import React from "react"
import { makeStyles } from "@material-ui/core/styles/index"
import { Container, Grid } from "@material-ui/core/index"
import { createMuiTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import Rating from "@material-ui/lab/Rating"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Layout from "../components/layout"
import MainToolbar from "../components/main-toolbar"
import SEO from "../components/seo"
import LineTitle from "../components/LineTitle"
import backgroundImage from "../images/docHands.jpg"
import { injectIntl } from "gatsby-plugin-intl"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#000",
    },
  },
  background: {
    margin: 0,
    padding: 0,
  },
  bannerRight: {
    display: "flex",
    justifyContent: "flex-end",
    // alignSelf: 'flex-end',
    // paddingRigth: '-100px',
  },
  mainContainer: {
    padding: "0 !important",
    paddingBottom: "120px",
    backgroundColor: "#202020",
  },
  container: {
    backgroundColor: "transparent",
  },
  background: {
    padding: 0,
    background: `#333 url(${backgroundImage}) 50% 0 no-repeat`,
    backgroundSize: "cover",
    [theme.breakpoints.up("sm")]: {
      minHeight: "600px",
    },
    paddingBottom: theme.spacing(5),
  },
  bannerGrid: {
    // background: 'linear-gradient(to right, #AAB5A8, #E0E3E0)',
    flexDirection: "row",
    ["@media (max-width:600px)"]: {
      paddingBottom: "60px",
    },
  },
  treatmentsBanner: {
    paddingTop: "85px",
    justifyContent: "flex-end",
    ["@media (max-width:600px)"]: {
      display: "none",
    },
  },
  tooLongtitle: {
    ["@media (max-width:600px)"]: {
      fontSize: "2.4rem",
    },
  },
  tooLongSubheader: {
    color: "white",
    textTransform: "uppercase",
    align: "left",
    marginTop: "20px",
    maxWidth: "550px",
    ["@media (max-width:600px)"]: {
      fontSize: "1.3rem",
    },
  },
  docMobile: {
    ["@media (min-width:600px)"]: {
      display: "none",
    },
  },
  pageTitle: {
    paddingTop: "80px",
    // ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
    //   fontSize: '2rem',
    // }
  },
  ratingContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    flex: "0.65",
    ["@media (max-width:600px)"]: {
      flex: "1",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "1800px",
    // justifyContent: 'center',
    alignItems: "center",
    paddingTop: "100px",
    paddingBottom: "120px",
    backgroundColor: "#202020",
  },
  listItemz: {
    paddingLeft: "14px",
    lineHeight: 1.3,
    listStyleType: "none",
    "&:before": {
      color: "#909690",
      float: "left",
      margin: "0 0 0 -1em",
      width: "1em",
      content: "'-'",
    },
  },
  cardInnerContainer: {
    paddingLeft: "80px",
    paddingRight: "80px",
  },
  header: {
    color: "white",
    fontWeight: "bold",
  },
  content: {
    color: "white",
    paddingBottom: "16px",
  },
  ratingsType: {
    color: "white",
    padding: "40px",
    textAlign: "left",
    fontSize: "40px",
    fontWeight: "lighter",
  },
}))

const Reviews = ({ intl, location }) => {
  const classes = useStyles()

  const ratingDataNon = [
    {
      header: "Juni 2018 Mw. H.L - Botox en fillers",
      content: (
        <p>
          Doordat Susan eerst mijn huisarts was, was ik al bij het eerste
          consult zeer op mijn gemak en vertrouwd. Zij neemt uitgebreid de tijd
          voor een analyse en deskundig advies en beantwoord uitgebreid alle
          vragen. Ik was erg voorzichtig voor cosmetische behandelingen door
          onnatuurlijke resultaten die ik zag bij vriendinnen, voor mij is een
          natuurlijke look essentieel i.v.m. mijn modellenwerk.Heel natuurlijk
          resultaat. Susan heeft die wens erg serieus genomen en het resultaat
          is helemaal naar verwachting. Ik zou gegarandeerd een eventuele
          volgende behandeling ook bij Face Institute ondergaan en tip al mijn
          vrienden en vriendinnen dat zij met een gerusthart hier alle
          cosmetische behandelingen kunnen deskundig en professioneel kunnen
          ondergaan
        </p>
      ),
    },
    {
      header: "Augustus 2018, Caroline - Botox en fillers",
      content: (
        <p>
          Via mijn werk kwam ik in contact met Susan Schmidt en heb inmiddels
          meerdere behandelingen ervaren. Susan informeert je duidelijk en
          stapsgewijs over de behandeling en stelt je erg op je gemak. Altijd
          een eerlijk advies! De behandelingen zijn niet pijnlijk, er wordt goed
          gekoeld waardoor ik nauwelijks last heb van blauwe plekken. De
          behandeling vind plaats in een prachtige, fijne ruimte en zelfs de
          behandelstoel is heerlijk verwarmd. Je kunt met al je vragen bij Susan
          terecht, zij geeft overal een eerlijk antwoord op. Ik heb volledig
          vertrouwen in de expertise van Face Institute en kom zeker terug!
        </p>
      ),
    },
    {
      header: "December 2018 - Huid verbetering",
      content: (
        <p>
          Sinds 6 maanden pukkelvrij… en dat na 41 jaar. Ik heb al vanaf mijn
          jeugd last van onstekingen in mijn gezicht en heeft mijn leven
          grotendeels beheerst met onzekerheid. Na veel behandelingen van
          huidtherapeuten, peelings, micro dermabrasie en chemische
          behandelingen constateerrde ik dat mijn huid er alleen maar slechter
          van werd. Voor mijn lijntjes op mijn voorhoofd onderging ik een
          botoxbehandeling bij Susan Schmidt, en we raakte aan de praat over
          mijn huidconditie. Susan adviseerde mij receptmiddelen en ben nu bijna
          6 maanden pukkelvrij! Het is nog niet te bevatten dat het weg is, ik
          ben ontzettend blij en dankbaar, het heeft mijn leven veranderd!
        </p>
      ),
    },
    {
      header: "2019 Denise - Botox en fillers",
      content: (
        <p>
          Een vriendin heeft mij geadviseerd om naar Face Institute te gaan door
          haar goede ervaring. Doordat ik ruim 12 kilo was afgevallen werden
          lijntjes en rimpels meer zichtbaar in mijn gezicht. Susan stelde mij
          goede en kritische vragen waar ik mij aan stoorde en gaf op basis van
          mijn wensen een stap voor stap behandelplan. Zo kon ik zelf kiezen wat
          ik wel en niet wilde laten doen en wanneer. Mijn huid is weer vol en
          gevuld, diepe lijnen en verzakkingen zijn niet meer zichtbaar, mijn
          huid ziet er echt weer jonger uit. Ik waardeer Susan voor haar
          eerlijke advies en natuurlijke resultaten. Zij stelt je erg op je
          gemak en is prettig in de omgang. Ik heb al diverse vriendinnen
          geadviseerd om naar Susan toe te gaan en ook zij zijn lovend over haar
          expertise en de natuurlijke resultaten. Voor mij een vast adres voor
          het nodige onderhoud.
        </p>
      ),
    },
    {
      header: "Augustus 2019, Mrs. T.S / London - Botox en fillers",
      content: (
        <p>
          Came to Dr Schmidt from London, even if there are numerous doctors
          doing injectable treatments at home, because of Dr Schmidt’s approach
          in applying her treatments. Previously my doctor in London had only
          chose to treat my forehead and eye region with a lot of Botox, and I
          was never that happy with the resulting unlined but heavy look. Dr
          Schmidt’s approach is for the whole face and to use a combination of
          injectables to lift rather than immobilise the face. The result is
          natural, softer and altogether fresher.'
        </p>
      ),
    },
    {
      header: "September 2019",
      content: (
        <p>
          Altijd nog wat huiverig voor botox en fillers, heb ik mij de afgelopen
          jaren bij verschillende artsen laten voorlichten, zowel in binnen- als
          buitenland. Waar ik bij de een op het eerste gezicht goede informatie
          kreeg wilde een andere al direct een spuit zetten zo van ‘zegt u het
          maar’. Mijn verzamelde informatie en ervaringen gaven mij niet het
          vertrouwde gevoel om botox of fillers te nemen. De laatste tijd heeft
          mijn gezicht te lijden gehad onder de overgang, vermagerd in mijn
          gezicht, het verouderingsproces sloeg ineens hard toe. Mijn gezicht
          past niet meer bij hoe ik mij voel en merk dat ik spiegels begin te
          vermijden. Dit kan niet de bedoeling zijn als je 50 jaar bent.
          <br></br>
          <br></br>Via mijn schoonheidsspecialiste bij Susan terecht gekomen. En
          hier hoor je dus het hele verhaal van A tot Z. Over de intreding van
          het verouderingsproces, wat dit met je huid maar ook met je
          botstructuur doet. Ze maakt een uitgebreide analyse van je gezicht en
          legt uit wat er nodig is om je gewenste resultaat te behalen. In mijn
          geval een frisse uitstraling en het proces van veroudering vertragen
          of de zelfs de klok een paar jaartjes terug zetten. Uiteraard zonder
          dat iemand zegt ‘Wat heb jij nou gedaan?!’ Voor alle duidelijkheid ik
          wil niet zo’n typisch ‘botox’ gezicht of te grote opgespoten lippen.
          <br></br>
          <br></br>
          Wanneer je zoals ik 50 jaar bent dan is het verouderingsproces al ruim
          20 jaar bezig. Susan legt uit; ‘Zie het als een huis met 20 jaar
          achterstallig onderhoud, dit knap je niet volledig op door alleen een
          likje verf .' Zo ook met het gezicht. Waar het op neer komt is een
          gefaseerde behandeling, een combinatie van botox en fillers, verspreid
          over een termijn waar je zelf voor kiest en ook wat je portemonnee aan
          kan. Susan geeft eerlijke, transparante informatie, is daarbij heel
          open over haar eigen proces tegen veroudering. Ze heeft samenwerking
          met twee specialisten die behoren tot de wereldtop van hun vakgebied.
          Ze gaat voor het hoogste resultaat en dat geeft mij een enorm
          vertrouwen om dit proces met Susan te doen.
          <br></br>
          <br></br>
          De passie en deskundigheid spatten ervan af! Het is in deze tijd van
          zoveel aanbod op dit gebied niet makkelijk om een goede, betrouwbare
          arts te vinden, maar ik durf te zeggen dat ik haar heb gevonden!
        </p>
      ),
    },
  ]

  const ratingDataFacelift = [
    {
      header: "2019 - Deep Plane Facelift and Necklift",
      content: (
        <p>
          Zeer tevreden. Iderereen die overweegt om facelift te laten doen zou
          ik adviseren om naar dr faris te gaan. Het resultaat is echt prachtig
          en Iedereen zegt dat zie je goed uit maar niemand ziet dat er een
          behandeling is gedaan. Ik ben blij dat ik Deep plane facelift heb
          laten doen.
        </p>
      ),
    },
    {
      header: "2019 - Deep Plane Facelift and Necklift",
      content: (
        <p>
          A time ago I had a deep plane facelift and necklift with Dr. Faris. On
          recommodation I visit Dr. Faris for consulting. He took plenty
          sufficient time and attention during all visits/calls. I am extremely
          pleased with the guidance and aftercare. The care before and after the
          operation was also fantastic. Everyone took sufficient time /
          attention. Dr. Faris is extremely concerned about the progress of the
          recovery. The result was very successful and so natural. Familiar
          people have not noticed anything; just you look so good! I recommend
          Dr. Faris for his superior skills and expertise in facial surgery. You
          can trust to put your beauty in his hands 5 stars
        </p>
      ),
    },
    {
      header: "2019 - Deep Plane Facelift and Necklift",
      content: (
        <p>
          zeer tevreden Iederereen die overweegt om facelift te laten doen zou
          ik adviseren om naar dr Faris te gaan Het resultaat is echt prachtig
          en iedereen zegt: wat zie je er goed uit, maar niemand ziet dat er een
          behandeling is gedaan. Ik ben blij dat ik een Deep Plane Facelift heb
          laten doen
        </p>
      ),
    },
    {
      header: "2019 - Deep Plane Facelift and Necklift",
      content: (
        <p>
          Zes weken geleden heb ik een Deep Plane facelift gehad gedaan door Dr.
          Faris. Ik ben zo blij dat ik met hem in contact ben gekomen en zijn
          expertise heb mogen ervaren! Het resultaat is mooi, omdat het zo
          natuurlijk is. Geen verandering in expressie. "Wat zie je er goed uit
          en uitgerust" is wat anderen tegen me zeggen. Ik ben Dr. Faris
          dankbaar voor zijn toegankelijkheid, toewijding, professionaliteit en
          expertise. Hij is een geschenk uit de hemel, voor iedereen die besluit
          met hem samen te werken en de operatie door hem te laten uitvoeren.
        </p>
      ),
    },
    {
      header: "2018 - Deep Plane Facelift and Necklift en littekencorrectie",
      content: (
        <p>
          Face Lift en littekencorrectie. De reden dat ik voor Dr. Vuyk & Dr.
          Faris heb gekozen, is dat ze gespecialiseerd zijn in de specifieke
          operatie die ik nodig had. Tijdens het consult luisterden de artsen
          heel geduldig naar mijn wensen en wisten waar ze me konden helpen met
          betrekking tot mijn littekenweefsel. Plannen werden gemaakt en
          besproken tijdens die consulten en tijdens het pre-operatieve gesprek.
          Dit zorgde ervoor dat ik me op mijn gemak voelde. Nadat ik wakker was
          geworden van de operatie kwamen beide artsen kijken/praten om te zien
          of het goed met me ging. Een dag na de operatie ging ik terug om mijn
          verband te laten verwijderen en een week later om mijn hechtingen te
          verwijderen. Een half jaar na de operatie heb ik een check-up afspraak
          om te zien hoe ik de wonden genezen. In de tussentijd heb ik via
          WhatsApp contact met Dr. Faris om de voortgang te bespreken en foto's
          te sturen. Op het moment van schrijven was mijn operatie 4 maanden
          geleden en tot nu toe ben ik erg blij met de resultaten, ondanks het
          feit dat het genezingsproces nog lang niet klaar is. Ik raad Dr. Vuyk
          en Dr. Faris zeker aan anderen aan. Ze zijn goed in hun vak en weten
          precies wat ze moeten doen. Daarnaast zijn ze ook erg vriendelijk en
          zijn ze zeer geïnteresseerd in hoe het herstel verloopt.
        </p>
      ),
    },
    {
      header: "2018 - Deep Plane Facelift and Necklift",
      content: (
        <p>
          Facelift. Zeer respectabele ervaren artsen. Ze zijn experts op het
          gebied van plastische chirurgie aan het gezicht. Vanwege een
          bacteriële infectie (toxoplasmose) moest ik de lymfeklieren onder mijn
          kin chirurgisch laten verwijderen toen ik 3 jaar oud was. Het
          resultaat was een lang ( 9,5 cm) en breed (0,5 cm) litteken. Vanaf
          mijn 40te begon het litteken te rimpelen, omdat de huid slap werd, het
          hing in een punt, kreeg ik een onderkin. Dit gaf me een oud en
          vermoeid uiterlijk. 7 jaar en 4 consultaties met verschillende
          plastisch chirurgen later, vond ik via google een lovend artikel over
          Dr. Vuyk "Plastic and Reconstructive Facial Surgery". Tijdens het 2e
          consult legden Dr. Vuyk en Dr. Faris mij vakkundig en zorgvuldig uit
          wat de beste optie voor mij was. Een nekfacelift, resulterend in een
          strakke kin en een glad litteken, waardoor het litteken minder opviel.
          Door deze grondige uitleg durfde ik eindelijk de stap te zetten. De
          uitstekende voorbereiding, de operatie en nazorg, zorgt ervoor dat u
          precies weet wat u als patiënt kunt verwachten en waaraan u zich moet
          houden voor een optimaal herstel en resultaat. Dr. Vuyk en Dr. Faris
          voerden de procedure samen uit. Na de operatie heb je 3 dagen last
          gehad omdat je niet plat kunt slapen. Maar na 1 week is de milde pijn
          verdwenen. Het herstel van de operatie verliep precies zoals
          beschreven was in de brochure. Ik was ten onrechte bezorgd over de
          nieuwe littekens, want deze zijn nu al bijna niet te zien. De operatie
          is slechts 8 weken geleden uitgevoerd, maar het resultaat ziet er nu
          al goed uit. Ik heb geen onnatuurlijk strak gezicht, het is niet
          duidelijk dat ik een nek aan nek heb gehad en het geheel ziet er
          "fris" uit. Ik heb een strakkere kaaklijn dan ik ooit heb gehad. Ik
          ben echt heel tevreden met het resultaat. Zowel Dr. Vuyk als Dr. Faris
          zijn zeer respectabele artsen, ze zijn experts op het gebied van
          plastische chirurgie voor het gezicht, en ik raad deze artsen aan
          iedereen aan die, net als ik, een probleem in hun gezicht heeft.
        </p>
      ),
    },
  ]

  const ratingDataRhinoplasty = [
    {
      header: "2018 - Revisie neuscorrectie",
      content: (
        <p>
          Geen kleine prestatie die er gedaan moest worden en ik dacht eigenlijk
          niet dat het mogelijk zou zijn. Vanaf het moment van het consult tot
          aan 6 maanden daarna heb ik alleen maar de beste zorg ontvangen.
          Telefoontjes aan het begin, follow-up e-mails, en altijd het aanbod om
          opnieuw een afspraak te maken of te chatten. De faciliteiten tijdens
          het consult en de operatie waren uitzonderlijk, er waren geen
          vertragingen, mijn zenuwen werden verlicht en ik werd heel goed
          verzorgd. Ondanks het feit dat ik nog maar 6 maanden werk (en ik
          begrijp na een paar neuscorrecties dat ik een jaar moet wachten
          voordat ik de definitieve resultaten zie), ben ik tot nu toe erg
          tevreden. Ik zal nooit een perfecte neus hebben, maar dr. Faris deed
          alles wat hij zei dat hij zou proberen en doen. Er waren geen valse
          beloften en mijn neus is veel beter (ademhalingswijs en esthetisch)
          dan voorheen. Ik kan hem en het team niet genoeg bedanken.
        </p>
      ),
    },
    {
      header: "2018 - Revisie neuscorrectie",
      content: (
        <p>
          Ik raad Dr. Faris's ten zeerste aan. Ik ben erg blij met de resultaten
          van mijn neuscorrectie. Voorheen werd ik behandeld twee chirurgen,
          echter die wilde mij niet meer opereren. Dus ik moest een expert
          vinden die bekwaam was in het gebied waar ik aan geholpen moest
          worden. Van overleg tot de operatie helemaal tot aan de follow-up die
          ik op de dag van vandaag nog steeds krijg, de bekwaamheid en service
          van Dr. Faris aan hem en zijn team zijn groot.
        </p>
      ),
    },
    {
      header: "2018 - Revisie neus correctie en littekencorrectie",
      content: (
        <p>
          Ik ging naar Dr. Faris en zijn collega dr. Vuyk voor een complexe
          littekenvorming die was gevormd door een eerdere
          rhinoplastie-infectie. Het was een uitdaging om chirurgen te vinden
          die dit probleem in mijn eigen land, Zweden, konden aanpakken. Dat is
          de reden dat ik naar Amsterdam ben afgereisd om een expert in nasale
          reconstructieve chirurgie te vinden. Het litteken moest operatief
          worden verwijderd en gereconstrueerd met een flap. (Vroegere pogingen
          door sommige "respectabele” klinieken in Zweden waren laser,
          cryotherapie en "vulstoffen". Deze "procedures" maakten het probleem
          eigenlijk erger en het litteken dikker). Ik kan niet genoeg
          benadrukken hoe belangrijk het is om goed opgeleide chirurgen te
          vinden, wanneer het gaat om dit soort problemen. Ik ben erg blij met
          het hele proces, van consult tot aan de operatie. Dankzij de goede
          communicatie met het personeel via Skype en e-mail was het heel
          eenvoudig om alles te regelen.
        </p>
      ),
    },
    {
      header: "Revisie neuscorrectie",
      content: (
        <p>
          Ik ben U zeer dankbaar voor de geslaagde ingreep, waardoor ik een stuk
          beter en zekerder in het leven sta en bovendien geen of weinig last
          heb van een verstopte neus. De reden, waarom ik opzag tegen de
          ingreep, is mijn ervaring van de vorige vijf niet al te plezierige
          ingrepen. Ik heb geen moment aan uw capaciteiten getwijfeld. In
          november 2011 ben ik met veel succes geopereerd door dr. Vuijk, naar
          mijn mening een zeer kundige, aardige en vooral betrokken KNO arts.
          Hiervoor was ik, als gevolg van een ongeval, al vijfmaal eerder
          geopereerd door verschillende KNO artsen en zgn, plastisch chirurgen.
          Het resultaat was bedroevend, zowel esthetisch als functioneel. Ik ben
          jaren opzoek geweest naar een arts die de ingreep uit kan voeren, geen
          van de geraadpleegde artsen durfde het aan of kwam tot de conclusie
          dat er niks meer aan te doen was en maar leren ermee leven. Op advies
          van geraadpleegde plastisch chirurgen, ben ik in contact gekomen met
          dr. Vuijk. Hij bleek de enige te zijn met de nodige kennis, kunde en
          ervaring die de zeer gecompliceerde ingreep aandurfde. Nogmaals met
          veel succes, het heeft mijn leven een stuk aangenamer gemaakt, mijn
          neus staat recht, is symmetrisch, ik kan weer goed ademen. Mijn advies
          aan de mensen die een dergelijke ingreep moeten ondergaan is, trek je
          niets aan van eventuele negatieve publiciteit! Dr. Vuijk, nogmaals
          heel erg bedankt, ook voor de nodige ondersteuning bij het conflict
          met Delta Lloyd over het vergoeden van de ingreep. F.S., Revisie
          Rhinoplastiek
        </p>
      ),
    },
    {
      header: "Revisie neuscorrectie",
      content: (
        <p>
          Het is zeker al 15 jaar geleden dat U mij als chirurg geholpen hebt
          een septumplastiek en plastische correctie toe te passen aan mijn
          neus, en het is een hele mooie neus geworden! Daar ben ik u zeer
          erkentelijk voor. Alleen de laatste jaren ben ik mij een beetje
          schuldig gaan voelen ten opzichte van u daar nooit geen blijk van te
          hebben gegeven. Ik wilde wel, maar was alles kwijt. Mijn geheugen liet
          me volledig in de steek, wist alleen nog vaag iets van Uw ziekenhuis.
          En oh ja ooit heeft uw secretaresse nog gebeld voor foto's er na en
          daar ben ik niet aan toe gekomen. Vele jaren later pas kreeg ik het
          gevoel een vreselijke ondankbare patiënt te zijn en dat U veel beter
          had verdient. Tot dat ik vandaag bij het opruimen van een kast een
          knipsel vond van het tijdschrift waardoor ik in der tijd bij U terecht
          ben gekomen. Het was een uiteenzetting over een nieuwe neus, een
          nieuwe methode door U gedaan. Pardonne moi dat ik uw naam vergeten was
          en blij dat ik alsnog U kan bedanken mille mille fois. I.M.,
          neuscorrectie
        </p>
      ),
    },
    {
      header: "Revisie neuscorrectie",
      content: (
        <p>
          Nogmaals wil ik U van harte bedanken voor uw zorg en
          toegewijdeexpertise. Ik ging de operatie in met de meest
          afschuwwekkende beelden enscenario's voor ogen. Het moeilijkste
          daarbij was te vertrouwen, en me volledig over te geven aan uw handen
          en kundigheid. T.B., neuscorrectie (Rhinoplastiek) Ik kan U niet
          vertellen hoe blij ik was, toen ik uit de narcose bijkwam en voelde
          dat mijn gezicht, op mijn neus na, nog intact was. Ik ben u zo
          ontzettend dankbaar dat mijn gezicht behouden bleef. Het kapje is
          inmiddels van mijn neus. Er zijn nog geen twee weken voorbij en nu al
          is het zover hersteld dat ik met het grootste gemak de straat opga.
          Als iemand me met een blik op mijn neus vraagt of ik op mijn gezicht
          gevallen ben, antwoord ik met een knipoog dat ik gevochten heb. En ik
          heb gewonnen, zeg ik. Aan alle kanten. Duizend maal dank en alle lof
          aan u.
        </p>
      ),
    },
    {
      header: "Revisie neuscorrectie",
      content: (
        <p>
          Ik kan me nog goed herinneren dat uw assistente opbelde met de
          mededeling dat er aanvankelijk geen mogelijkheid was op korte termijn,
          maar toen ik mijn persoonlijke situatie bekend maakte, werd ik
          gelukkig binnen enkele weken teruggebeld met de mededeling dat er een
          mogelijkheid was om binnen 2 weken te opereren. De voorbereiding bij
          het bezoek aan uzelf heb ik als bijzonder prettig ervaren. Ook uw
          uitleg van hetgeen komen zou gaan en wat wel dan wel niet te
          verwachten. De opmerking over het gevoel van mijn eigen neus klopte
          inderdaad. Als ik terug denk aan de ingreep dan heb ik er geen spijt
          van gehad. Ik ben U en uw team erg dankbaar.
          <br></br>
          <br></br>
          Na de ingreep vond ik het erg verrassend, maar bovenal erg prettig dat
          U persoonlijk belde om te vragen hoe het met me ging. Uw betrokkenheid
          en bevlogenheid spreekt me erg aan. Ik hoop dat U door blijft gaan met
          uw goede werk en ook uw bevlogenheid blijft houden. Nogmaals bedankt.
        </p>
      ),
    },
    {
      header: "Revisie neuscorrectie",
      content: (
        <p>
          Enkele jaren geleden heeft U, tezamen met uw team van specialisten,
          mij geholpen bij een correctie aan mijn neus. Ik wil U graag alsnog
          bedanken voor hetgeen u gedaan heeft. Het is destijds een zeer
          bijzondere gebeurtenis voor mij geweest. Uw betrokkenheid en
          bevlogenheid spreekt mij erg aan! I.J.N.M.H. te Oudenbosch,
          neuscorrectie (Rhinoplastiek)
        </p>
      ),
    },
    {
      header: "Revisie neuscorrectie",
      content: (
        <p>
          Onlangs ben ik aan mijn neus geopereerd. Mijn complimenten en alle lof
          aan Dr Vuyk, de Kno-poli, de operatie-kamer, kortom aan alle mensen in
          de kliniek waarmee ik te maken had. Ik heb er een betrokkenheid en
          rust ervaren die heel kalmerend werkte. Het was voor mij een heel
          positieve ervaring. Voor de behandeling heb ik alleen maar heel goede
          woorden. Dr Vuyk en iedereen:” BEDANKT!” M.S.S. te Heerhugowaard,
          neuscorrectie
        </p>
      ),
    },
  ]

  const ratingDataNoseRecon = [
    {
      header: "Neusreconstructie",
      content: (
        <p>
          Geachte dr. Vuijk Hierbij wil ik u hartelijk bedanken. U heeft heel
          mooi werk gemaakt van mijn gezicht. Het was voor mij een eer dat U mij
          wilde helpen. De laatste keer dat ik bij U op het spreekuur was had ik
          U nog niet bedankt, vandaar via deze weg. Ook de mensen op de OK en da
          afdeling bedankt. Het voelt als een warme deken! P.S. U heeft mij
          zelfvertrouwen teruggegeven. L.J.R., Neusreconstructie
        </p>
      ),
    },
    {
      header: "Neusreconstructie",
      content: (
        <p>
          19 december 2011 kwam ik voor een spoedbehandeling bij dokter Vuyk
          terecht. Ik ben als Zaanse boer helemaal niets gewend qua
          ziekenhuizen, en alles wat ik ooit heb meegemaakt weet ik via mijn
          vrouw die helaas wel (bijna bij naam) bekend is in het ZMC. Vanaf het
          moment dat ik binnenkwam in Blaricum, werd ik aangesproken door de
          receptiemedewerker. Zij zag aan mijn neus ( grote dikke pleister) dat
          ik voor dokter Vuyk kwam en stelde mij direct op mijn gemak en wees
          mij de weg, maar ik moest eerst een ponskaartje maken. Ook daar werd
          gelijk persoonlijke aandacht gegeven. Toen naar de afdeling KNO.
          <br></br>
          <br></br>
          Ook daar weer een en al vriendelijkheid en persoonlijke aandacht, dit
          had ik echt nog nooit meegemaakt. Dokter Vuyk kwam vanaf het eerste
          moment al heel geruststellend over en ik had vanaf dat moment gewoon
          het volste vertrouwen in deze man. Twee dagen later werd ik voor het
          eerst geopereerd en waar ik ook kwam, iedereen nam de tijd voor me om
          alles uit te leggen en de adviezen waren geen praatjes, maar nodige
          tips. (Situatie na dat de tumor in toto verwijderd is en de wond
          randen afgedekt met eigen huid transplantaat. Rechts reconstructie van
          het kraakbeen skelet en bedekking met voorhoofdlap.) Bij de volgende
          operatie werd ik twee dagen opgenomen en wat mij daar opviel was, dat
          veel jonge verpleegsters eerst schrokken, maar daarna een gesprek aan
          gingen om zich te verdiepen in hetgeen er in mij omging. Helemaal top!
          Bij de laatste twee operaties was het voor mij meer routine en hoefde
          ik geen uitleg meer te ontvangen of te geven, ik werd zelfs regelmatig
          herkend. Bij deze wil ik iedereen, niemand uitgezonderd, heel
          hartelijk bedanken voor jullie grandioze inzet. De directie mag
          uiterst trots zijn op de medewerkers. Ik hoop dat dit stukje in
          bijvoorbeeld een personeelsblad afgedrukt kan worden, zodat alle
          medewerkers dit bedankje mogen lezen. Met vriendelijk groet, H.B.,
          Zaandam. Subtotale neusreconstructie
        </p>
      ),
    },
    {
      header: "Neusreconstructie",
      content: (
        <p>
          Dr Vuyk: Fijne chirurg, prima opvang, geweldig operatie team. Zeer
          tevreden. B.L., Uithoorn, Neusreconstructie
        </p>
      ),
    },
    {
      header: "Neusreconstructie",
      content: (
        <p>
          Dr Hade had about 90% of succes rate where world rate is 40%, not to
          mention that in Poland they don't even provide such research. I
          started writing to Doctor Vuyk in June 2010. We exchanged a lot of
          e-mails where Doctor Vuyk was explaining many things concerning
          closing our son's perforation. I deeply admire Dr Vuyk that despite of
          his hard work, operations, consultations, I can imagine that such very
          skilled Doctor have to be busy, he was finding time answering in
          details on each question I had. My contact with Dr Vuyk was better
          that with most of the doctors in Poland. I also have read a lot of his
          publications and medical works and I knew from the beginning that he
          is an excellent rhinologist and facial surgeon. In October or November
          2011 when we as parents and including our son Sebastian who had the
          biggest interest, we were deeply convinced that he is the most proper
          Doctor to perform operation, we went for the first visit before
          operation. One more time we saw that he is not only an excellent
          Doctor but also a very caring person about his patients. In very
          simple words and peacefull way he explained us how the operation was
          going to be done.
          <br></br>
          <br></br>
          Me and my husband, and our son came in December 2011 to the hospital
          to have the operation performed by Dr Hade Vuyk. We were very nervous
          but before the operation we heard from the hospital staff, it was
          probably the nurse, that Doctor Vuyk is an excellent and very skilled
          Doctor and many people from all over the Europe are coming to him.
          Those words eased our tension almost completely, we knew our son is in
          the safe hands. Dr Vuyk performed the operation in December. During
          the operation Doctor closed NSP and corrected his septum, everything
          went well. The process of healing also went very fast. After staying
          about 10 days and last visit, we went back home to Poland. After
          operation I had a lot of questions and again I have got the full
          explanation for each of my questions from the Doctor. Now my son
          breathing is better, he doesn't have deasiness and his perforation is
          closed. Sebastian is very, very lucky that he met You in some moment
          of his life. In simply words we are very happy that we have found such
          a good Doctor and people in Netherlands are lucky having him in their
          country. I recomend him in every possible way to people who need
          operation in Dr Vuyk's specialization. We deeply admire his work and
          good approach to patients. Best Regards, Beata Nalepa Gdansk, Poland
        </p>
      ),
    },
    {
      header: "Neusreconstructie",
      content: (
        <p>
          Our son started having nasal septum perforation. Because I knew that
          this is a very difficult operation where even reperforation can occur,
          I started looking for someone with a really good reputation as well as
          a high success rate in such operations.I contacted Dr Kridel from
          Huston Texas and he recommended me Dr Hade Vuyk from Netherlands.
          Beata Nalepa Gdansk, Poland. Rhinoplastiek
        </p>
      ),
    },
  ]

  const ratingDataEye = [
    {
      header: "Ooglidcorrectie",
      content: (
        <p>
          Mijn ooglidreconstructie ging perfect! Dokter Faris en dokter Vuyk
          zijn beiden erg bekwaam en hebben voor mij kunnen doen waar er in
          normale ziekenhuizen werd gezegd: het is niet mogelijk om dit te
          corrigeren, u moet er mee leren leven. Alles was geweldig! Van de
          eerste consultaties waarin alles volledig werd uitgelegd wat er zou
          gebeuren, tot de operatie zelf die goed ging en de vervolgconsulten
          natuurlijk. En het resultaat is zelfs beter dan ik had gehoopt!
        </p>
      ),
    },
    {
      header: "Reconstructie oogleden",
      content: (
        <p>
          U heeft bij mij kankerplekjes boven mijn oog en van mijn ooglid
          weggehaald (mohstherapie). Al 3 keer heeft U bij mij kwaadaardige
          plekjes verwijderd en hersteld. Het is steeds prachtig genezen en als
          je het niet weet zie je het niet. Al die keren was ik tevreden over de
          behandeling in Uw ziekenhuis. U bent zelf heel rustig en dan is alles
          en iedereen er omheen ook zo rustig en vriendelijk. Het helpt erg om
          op je de ademhaling te letten tijdens de akelige prikken die je dan
          goed kan verdragen. In de uitslaapkamer werd je verwend met een warme
          deken en maken zij het zo makkelijk mogelijk. Dit wilde ik U graag
          laten weten zodat andere patiënten met vertrouwen zich met vertrouwen
          door U en uw team laten behandelen. Hartelijk bedankt en vriendelijke
          groeten, A.S.D., Reconstructie oogleden
        </p>
      ),
    },
  ]

  const ratingDataEars = [
    {
      header: "Oorreconstructie",
      content: (
        <p>
          Lieve H Vuyk, Ik ben u zo dankbaar en ik weet zeker dat niemand anders
          het zo goed had gedaan. U mag echt trots op uzelf zijn. U heeft mij
          echt geholpen, ik zou niet willen weten wat het zonder u geworden was.
          Vanuit het diepste van mij hart bedankt, A.G., 8 jaar, reconstructie
          oorschelp na correctie elders
        </p>
      ),
    },
    {
      header: "Oorreconstructie",
      content: (
        <p>
          M.C. te Hoevelaken, Oorreconstructie Dank voor mooie oor en voor alle
          goede zorgen.
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </p>
      ),
    },
    {
      header: "Oorreconstructie",
      content: (
        <p>
          Lieve Dr. Hade Vuyk, De nadruk wil ik leggen op “lieve” omdat u bij
          mij een onuitwisbare indruk hebt achtergelaten. Een zeer warme,
          menselijke en kundige indruk. Even uitleggen wie ik ben: Ik ben degene
          die eind juni bij u kwam met vermoedelijke oor melanoom, met spoed
          doorgestuurd naar u door Dr. Brinkman. U heeft twee dagen later de
          vermoedelijke melanoom verwijderd uit mijn oor. Ik moet u eerlijk
          zeggen dat na de oor-reconstructie van 2 weken geleden en na mijn
          bezoek aan u vorige week, bij mij de klap kwam. U heeft mij veel
          deskundige steun en vooral vertrouwen gegeven in, voor mij, een paar
          zeer moeilijke maanden.
          <br></br>
          <br></br>
          Vertrouwen is een woord wat ik steeds terugvind bij u, in het lezen
          van uw website, maar ook in uw omgang met patiënten, waarvan in er 1
          ben/was de afgelopen maanden. De dag nadat ik bij u was afgelopen
          week, kwam ik terug bij Dr. Brinkmann. Zij vond ‘het oor’ er prachtig
          uitzien en ‘wist wel dat u degene was die dit het beste kon’ zo waren
          haar woorden. Toch had zij behoorlijk wat ‘kleenex’ nodig voor mij,
          maar ze zei dat de klap vaak kwam na de reconstructie. Ze was vol
          begrip. Ik heb toch erge angst gehad. Wel was ik ‘Het traject’
          ingegaan vol goede moed en optimisme. Tegelijk ook wetende dat ik
          vermoedelijk iets ernstig had. We wisten dit, net als u voor 99%
          zeker. En daarna die ene procent waaruit bleek dat ik geen melanoom
          had, dat was even teveel. Ik behoorde blij te zijn, maar toch was er
          een klap…. Wat zit een mens vreemd in elkaar…. Het sloeg behoorlijk
          toe! Ook zij toonde een menselijkheid en warmte, ze gaat me zelfs
          volgende week een keer bellen hoe het ermee gaat! Psychisch herstel
          duurt volgens Dr. Brinkmann veel langer dan lichamelijk herstel. Wat
          wil ik nu hiermee zeggen??? Vooral dat ik erg blij ben dat ik u heb
          leren kennen, de warmte, het respect en de deskundigheid waarmee u met
          mij om bent gegaan (en ongetwijfeld ook met andere patiënten). Dit
          heeft een diepe indruk op mij gemaakt. Het is niet de eerste keer dat
          ik gezondheidsproblemen heb gehad, maar nooit heb ik een dergelijke
          warmte, vriendelijkheid en interesse ervaren. Ik heb groot respect
          voor u en de gang van zaken in uw kliniek. HEEL veel dank voor alles,
          dat is het enige wat ik hiermee wil zeggen!! Tot ziens A.S.G.
        </p>
      ),
    },
  ]

  const [value, setValue] = React.useState(5)
  const CustomerRating = ({ header, content }) => {
    return (
      <div>
        <Box component="fieldset" mb={3} borderColor="transparent">
          <Typography component="legend" className={classes.header}>
            {header}
          </Typography>
          <Rating value={value} readOnly />
          <Typography component="legend" className={classes.content}>
            {content}
          </Typography>
        </Box>
      </div>
    )
  }

  const t = (id) => intl.formatMessage({ id })

  return (
    <Layout>
      <SEO
        lang={intl.formatMessage({ id: "global.lang" })}
        title={intl.formatMessage({ id: "reviews.SEOtitle" })}
        description={intl.formatMessage({ id: "reviews.SEO" })}
        path={location.pathname}
      />
      <MainToolbar />
      <Container maxWidth={"xl"} className={classes.mainContainer}>
        <Container maxWidth={"xl"} className={classes.background}>
          <Grid container spacing={0} className={classes.bannerGrid}>
            <Grid item xs={12} sm={6}>
              <Container>
                <LineTitle
                  header={t("reviews.header")}
                  subheader={t("reviews.subheader")}
                  className={classes.pageTitle}
                  titleStyle={classes.tooLongtitle}
                  subHeaderStyle={classes.tooLongSubheader}
                />
              </Container>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.bannerRight}></Grid>
          </Grid>
        </Container>
        <Container maxWidth={"xl"} className={classes.contentContainer}>
          <Container maxWidth={"xl"} className={classes.ratingContainer}>
            <h2 className={classes.ratingsType}>Non-surgical</h2>
            {ratingDataNon.map((rating) => (
              <CustomerRating
                header={rating.header}
                content={rating.content}
              ></CustomerRating>
            ))}
            <h2 className={classes.ratingsType}>Facelift</h2>
            {ratingDataFacelift.map((rating) => (
              <CustomerRating
                header={rating.header}
                content={rating.content}
              ></CustomerRating>
            ))}

            <h2 className={classes.ratingsType}>Rhinoplasty / Neuscorrectie</h2>

            {ratingDataRhinoplasty.map((rating) => (
              <CustomerRating
                header={rating.header}
                content={rating.content}
              ></CustomerRating>
            ))}
            <h2 className={classes.ratingsType}>
              Neusreconstructie / Nose reconstruction
            </h2>
            {ratingDataNoseRecon.map((rating) => (
              <CustomerRating
                header={rating.header}
                content={rating.content}
              ></CustomerRating>
            ))}

            <h2 className={classes.ratingsType}>Eyelid / Ooglidcorrectie</h2>

            {ratingDataEye.map((rating) => (
              <CustomerRating
                header={rating.header}
                content={rating.content}
              ></CustomerRating>
            ))}

            <h2 className={classes.ratingsType}>
              Oorreconstructie / Ear reconstruction
            </h2>

            {ratingDataEars.map((rating) => (
              <CustomerRating
                header={rating.header}
                content={rating.content}
              ></CustomerRating>
            ))}
          </Container>
        </Container>
      </Container>
    </Layout>
  )
}

export default injectIntl(Reviews)
